import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)


//进度条
import NProgress from "nprogress";
import "nprogress/nprogress.css";
NProgress.configure({ showSpinner: false });
const routes = [
  {
    path: '/',
    redirect: '/Home'
  },
  {
    path: '/Home',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
  },
]

const router = new VueRouter({
  routes
})
export default router
router.beforeEach((to, from, next) => {
  NProgress.start();
  next()
});

router.afterEach((to, from, next) => {
  NProgress.done();
});