import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'normalize.css/normalize.css'
import '@/assets/js/index.js'
import "babel-polyfill"
import Es6Promise from "es6-promise"
Es6Promise.polyfill()
// UI
import {
Carousel,
CarouselItem,
Image,
Rate,
} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(Carousel).use(CarouselItem).use(Image).use(Rate);
import { Video } from '@nutui/nutui';
Video.install(Vue);

import dataV from '@jiaminghi/data-view'
Vue.use(dataV)
Vue.config.productionTip = false
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
